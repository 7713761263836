import React from "react";
import banner from "../../../Assets/Images/About/banner.png";
import "./style.scss";
function Banner() {
  return (
    <div className="banner">
      <img src={banner} alt="" />
      <div className="banner-title">
        <div className="title">
          <h2>WHO WE ARE</h2>
        </div>
        <div className="subtitle">
          <p>넘을 수 없는 벽을 넘어 새로운 세상과 마주한다.</p>
        </div>
      </div>

      <div className="banner-detail">
        <p>
          일월은 이용자와 생산자가 경험을 공유하여 <br />
          벽을 쌓아 올리는 미래를 상상합니다.
          <br />
          일월은 공간의 가치를 높이며 <br />
          새로운 세상과 접하는 첫번째 벽(Wall) 입니다.
          <br />
        </p>
      </div>
    </div>
  );
}

export default Banner;
