import React, { useState } from "react";
import "./style.scss";
import logo from "../../Assets/Images/logo.png";
import arrowDown from "../../Assets/Images/open-button.png";
import { Link } from "react-router-dom";
import i18n from "i18next";
import { useTranslation } from "react-i18next";

function Header() {
    const { t } = useTranslation();
    const [lang, setLang] = useState("KOR");
    const [openDropdown, setOpenDropdown] = useState(false);
    const [showMenu, setShowMenu] = useState(false);
    const onChangeLang = (lang: string) => {
        if (lang === "ko") setLang("KOR");
        if (lang === "en") setLang("ENG");
        i18n.changeLanguage(lang);
        // sessionStorage.setItem("language", lang);
        console.log(i18n.language);
    };

    const dataMenu = [
        {
            title: "home",
            router: "/",
        },
        {
            title: "about",
            router: "/about",
        },
        {
            title: "space desing ",
            router: "/spacedesing",
        },
        {
            title: "installed gallery",
            router: "/installedgallery",
        },
        {
            title: "newsletter",
            router: "/newsletter",
        },
        {
            title: "contact",
            router: "/contact",
        },
    ];
    return (
        <>
            <header>
                {/* //logo  */}
                <div className="header-logo">
                    <div className="logo">
                        <Link to="/">
                            <img src={logo} alt="" />
                        </Link>
                    </div>

                    <div className="right-items">
                        <div onClick={() => setOpenDropdown(!openDropdown)} className="lang">
                            <span>{lang}</span>
                            <img src={arrowDown} alt="" />
                            {openDropdown && (
                                <ul className="list-lang">
                                    <li onClick={() => onChangeLang("ko")}>KOR</li>
                                    <li onClick={() => onChangeLang("en")}>ENG</li>
                                </ul>
                            )}
                        </div>

                        <div className="menu-mobile">
                            <div onClick={() => setShowMenu(!showMenu)} className="mobile-icon">
                                <div className={"nav-toggle" + (showMenu ? " open" : "")}>
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* /menu */}
                <ul className="menu">
                    {dataMenu.map((item, index) => (
                        <li key={index} className="menu-items">
                            <Link to={item.router}>{item.title}</Link>
                        </li>
                    ))}
                </ul>
            </header>

            <aside className={`list-menu-mobile ${showMenu ? "openMenu" : "closeMenu"}`}>
                {/* <div onClick={() => setShowMenu(false)} className="icon-close">
                    <p>X</p>
                </div> */}
                <ul className="menu-mobile-list">
                    {dataMenu.map((item, index) => (
                        <li key={index} className="menu-mobile-items">
                            <Link
                                to={item.router}
                                onClick={() => {
                                    setShowMenu(!showMenu);
                                }}
                            >
                                {item.title}
                            </Link>
                        </li>
                    ))}
                </ul>
            </aside>
        </>
    );
}

export default Header;
