import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import "./style.scss";
import arrow_right from "../../Assets/Images/Gallery/wh-r-60-arrow.png";
import { GalleryData } from "./GalleryData";

function Gallery() {
    let navigate = useNavigate();
    const location = useLocation();

    return (
        <div className="gallery-page">
            <div className="gallery-title">
                <h2>installed gallery</h2>
            </div>

            <div className="gallery-list">
                {GalleryData.map((item, index) => (
                    <div key={index} className="gallery-list-item">
                        <div className="item-content">
                            <div className="item-status">
                                <span className={`status ${item.status === "Completed" ? "status-complete" : ""}`}>{item.status}</span>
                                <div className="quantity">
                                    <span>{item.quantity}</span>
                                </div>
                            </div>

                            <div className="item-detail">
                                <div className="location">
                                    <span>{item.location}</span>
                                </div>
                                <div className="time">
                                    <span>{item.time}</span>
                                </div>
                            </div>

                            <div onClick={() => navigate(location.pathname + item.router)} className="item-img">
                                <img src={item.img} alt="" />
                                <div className="arrow-right">
                                    <img src={arrow_right} alt="" />
                                </div>
                                <div className="content-img">
                                    <p>{item.contentHover}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default Gallery;
