import React, { useCallback, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";

import "./style.scss";
import img1 from "../../../Assets/Images/commercial-01.png";
import img2 from "../../../Assets/Images/commercial-02.png";
import { Pagination } from "swiper";
function Commercial() {
    const sliderCommercialRef = useRef(null) as any;
    const [currentIndex, setCurrentIndex] = useState(1);

    const handlePagination = useCallback((index: number) => {
        if (!sliderCommercialRef.current) return;
        sliderCommercialRef.current.swiper.slideTo(index);
        setCurrentIndex(index + 1);
    }, []);

    const dataSlide = [img1, img2, img1];
    return (
        <main className="commercial">
            <div className="commercial-container">
                <div className="intro">
                    <div className="intro-title">
                        <div className="subtitle">Interactive Media Wall</div>
                        <div className="title">
                            <div className="intro-detail">
                                <h2>
                                    ABOUT
                                    <br />
                                    ILWALL
                                </h2>
                                <div className="right">
                                    <p>ILWALL은 박물관, 미술관 , 학교 , 기업로비 , 대형카페 등 다양한</p>
                                    <p>공간을 더 가치 있게 ’ 더 화려하게 ’ 더 특별하게 ’ 만들어 줄</p>
                                    <p>인터랙티브 미디어 월 (Interactive Media Wall) 브랜드 입니다.</p>
                                </div>
                            </div>
                            <p className="date">공간을 더 가치있게</p>
                        </div>
                        <div className="more">
                            <Link to="/about">MORE</Link>
                        </div>
                    </div>
                </div>
                <div className="slide-box">
                    <div className="slide">
                        <Swiper
                            slidesPerView={1.5}
                            spaceBetween={30}
                            ref={sliderCommercialRef}
                            modules={[Pagination]}
                            className="mySwiper"
                            onRealIndexChange={(e) => {
                                console.log("ddd", e.realIndex + 1);

                                setCurrentIndex(e.realIndex + 1);
                            }}
                        >
                            {dataSlide.map((item, index) => (
                                <SwiperSlide key={index}>
                                    <img src={item} alt="" />
                                </SwiperSlide>
                            ))}
                        </Swiper>
                        <div className="navigation-commercial-custom">
                            {dataSlide.map((item, index) => (
                                <div key={index} className={`custome-item ${currentIndex === index + 1 ? "activePagination" : ""}`} onClick={() => handlePagination(index)}>
                                    {index + 1}
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </main>
    );
}

export default Commercial;
