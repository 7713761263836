import React from "react";
import img1 from "../../Assets/Images/Space/story-img-01.png";
import img2 from "../../Assets/Images/Space/story-img-02.png";
import img3 from "../../Assets/Images/Space/story-img-03.png";
import img4 from "../../Assets/Images/Space/story-img-04.png";
import img5 from "../../Assets/Images/Space/story-img-05.png";
import img6 from "../../Assets/Images/Space/story-img-06.png";
import img7 from "../../Assets/Images/Space/story-img-07.png";
import img8 from "../../Assets/Images/Space/story-img-08.png";
import img9 from "../../Assets/Images/Space/story-img-01.png";

import "./style.scss";

const dataGallery = [
  {
    status: "Installing",
    quantity: "1 ilwall",
    img: img1,
    titleHover: 'DDP Digital Plaza \n Information Center',
    detailHover:
      "방문객들이 DDP에 자세하게 \n 알아볼 수 있는 DDP Infomation \n Center 인터랙션 미디어를 \n구축했습니다.",
  },
  {
    status: "Installing",
    quantity: "1 ilwall",
    img: img2,
    titleHover: 'DDP Digital Plaza \n Information Center',
    detailHover:
      "방문객들이 DDP에 자세하게 \n 알아볼 수 있는 DDP Infomation \n Center 인터랙션 미디어를 \n구축했습니다.",
  },
  {
    status: "Installing",
    quantity: "1 ilwall",
    img: img3,
    titleHover: 'DDP Digital Plaza \n Information Center',
    detailHover:
      "방문객들이 DDP에 자세하게 \n 알아볼 수 있는 DDP Infomation \n Center 인터랙션 미디어를 \n구축했습니다.",
  },
  {
    status: "Completed",
    quantity: "1 ilwall",
    img: img4,
    titleHover: 'DDP Digital Plaza \n Information Center',
    detailHover:
      "방문객들이 DDP에 자세하게 \n 알아볼 수 있는 DDP Infomation \n Center 인터랙션 미디어를 \n구축했습니다.",
  },
  {
    status: "Installing",
    quantity: "1 ilwall",
    img: img5,
    titleHover: 'DDP Digital Plaza \n Information Center',
    detailHover:
      "방문객들이 DDP에 자세하게 \n 알아볼 수 있는 DDP Infomation \n Center 인터랙션 미디어를 \n구축했습니다.",
      darkStatus: true
  },
  
  {
    status: "Installing",
    quantity: "1 ilwall",
    img: img6,
    titleHover: 'DDP Digital Plaza \n Information Center',
    detailHover:
      "방문객들이 DDP에 자세하게 \n 알아볼 수 있는 DDP Infomation \n Center 인터랙션 미디어를 \n구축했습니다.",
      darkStatus: true
  },
  {
    status: "Installing",
    quantity: "1 ilwall",
    img: img7,
    titleHover: 'DDP Digital Plaza \n Information Center',
    detailHover:
      "방문객들이 DDP에 자세하게 \n 알아볼 수 있는 DDP Infomation \n Center 인터랙션 미디어를 \n구축했습니다.",
      darkStatus: true
  },
  {
    status: "Completed",
    quantity: "1 ilwall",
    img: img8,
    titleHover: 'DDP Digital Plaza \n Information Center',
    detailHover:
      "방문객들이 DDP에 자세하게 \n 알아볼 수 있는 DDP Infomation \n Center 인터랙션 미디어를 \n구축했습니다.",
  },
  {
    status: "Completed",
    quantity: "1 ilwall",
    img: img9,
    titleHover: 'DDP Digital Plaza \n Information Center',
    detailHover:
      "방문객들이 DDP에 자세하게 \n 알아볼 수 있는 DDP Infomation \n Center 인터랙션 미디어를 \n구축했습니다.",
  },
 
  
 
];


function Space() {
  return (
    <div className="space-page">
      <div className="space-title">
        <h2>space desing</h2>
      </div>

      <div className="space-list">
        {dataGallery.map((item, index) => (
          <div key={index} className="space-list-item">
            <div className="item-content">

              <div className="item-img">
                <img src={item.img} alt="" />

                <div className="content-img">
                    <div className="title">{item.titleHover}</div>
                    <p className="detail">{item.detailHover}</p>
                    </div>

                    <div className="list-status">
                        <div className={`button-status ${item.darkStatus ? 'darkStatus' : ''}`}>
                            #{item.status}
                        </div>
                        <div className="button-status">
                            {item.quantity}
                        </div>
                    </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Space;
