import React from "react";
import "./style.scss";
function ArtService() {
    return (
        <main className="artService">
            <div className="mediaArt">{infoSection("Space Design", "POSCO Smart Data Center in lobby", "포스코 스마트 데이터 센타 로비")}</div>

            <div className="wallMuseum">
                <div className="info">{infoSection("Production Technology", "Digital Library", "전자앨범", false)}</div>
            </div>

            {/* <div className="viewMore">{infoSection("Art Service", "View More", "더보기")}</div> */}
        </main>
    );
}

export default ArtService;

const infoSection = (sub: string, title: string, extend: string, darkColor = true) => (
    <div
        style={{
            color: darkColor ? "#111" : "#fff",
        }}
        className="infoSection"
    >
        <div className="subtitle">{sub}</div>
        <div className="title">
            <h2
                style={{
                    color: darkColor ? "#111" : "#fff",
                }}
            >
                {title}
            </h2>
            <div className="extend">{extend}</div>
        </div>
    </div>
);
