import React from "react";
import { Link } from "react-router-dom";
import "./style.scss";

function Footer() {
    return (
        <footer>
            <div className="footer-container">
                <div className="footer-warpper">
                    <div className="line mid">
                        <Link to="/">
                            <p>개인정보처리방침</p>
                        </Link>
                        <Link to="/">
                            <p>이용약관</p>
                        </Link>
                        <Link to="/">
                            <p>영상정보처리기기 운영 관리 방침</p>
                        </Link>
                        <Link to="/">
                            <p>SITE MAP</p>
                        </Link>
                        <Link to="/">
                            <p>CONTACT US</p>
                        </Link>
                        <Link to="/">
                            <p>문화체육관광부</p>
                        </Link>
                    </div>
                    <div className="line bottom">ILWALL&nbsp;&nbsp;&nbsp;&nbsp;사업자등록번호 123-45-67789 서울시 강남구 논현로 151길 57, 1층 이보빌딩 ILWALL ⓒ ALL RIGHTS RESERVED.</div>
                </div>
            </div>
        </footer>
    );
}

export default Footer;
