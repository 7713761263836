import React from "react";
import "./style.scss";
import img1 from "../../../Assets/Images/main-list-img-1.png";
import img2 from "../../../Assets/Images/main-list-img-2.png";
import img3 from "../../../Assets/Images/main-list-img-3.png";
import img4 from "../../../Assets/Images/main-list-img-4.png";
import img5 from "../../../Assets/Images/main-list-img-5.png";
import img6 from "../../../Assets/Images/main-list-img-6.png";
import img7 from "../../../Assets/Images/main-list-img-7.png";
import img8 from "../../../Assets/Images/main-list-img-8.png";
import { Link } from "react-router-dom";

const dataCareer = [
    {
        id: 0,
        img: img1,
        en: "Production Technology",
        ko: "기술 개발",
    },
    {
        id: 1,
        img: img2,
        en: "Graphic Art",
        ko: "그래픽 아트",
    },
    {
        id: 2,
        img: img3,
        en: "Motion Planner",
        ko: "모션 플래너",
    },
    {
        id: 3,
        img: img4,
        en: "Experience Design",
        ko: "경험 디자인",
    },
    {
        id: 4,
        img: img5,
        en: "Project Management",
        ko: "프로젝트 관리",
    },
    {
        id: 5,
        img: img6,
        en: "Space Design",
        ko: "공간 디자인",
    },
    {
        id: 6,
        img: img7,
        en: "Construction Management",
        ko: "설치 관리",
        blackColor: true,
    },
    {
        id: 7,
        img: img8,
        en: "More",
        ko: "더 보기",
        blackColor: true,
    },
];
function Career() {
    return (
        <main className="career">
            <div className="title">Gallery</div>
            <ul className="menu">
                {dataCareer.map((item, index) => (
                    <li key={index} className="menu-item">
                        <Link to="/">
                            <img src={item.img} alt="" />
                            <div className="menu-item-content">
                                <div style={{ color: item.blackColor ? "#111" : "#fff" }} className="title-en">
                                    {item.en}
                                </div>
                                <div style={{ color: item.blackColor ? "#111" : "#fff" }} className="title-ko">
                                    {item.ko}
                                </div>
                            </div>
                        </Link>
                    </li>
                ))}
            </ul>
        </main>
    );
}

export default Career;
