import React from "react";
import banner_img_1 from "../../Assets/Images/DetailPage/view-01.png";
import banner_img_2 from "../../Assets/Images/DetailPage/view-02.png";
import banner_img_3 from "../../Assets/Images/DetailPage/view-03.png";
import detail_img_1 from "../../Assets/Images/DetailPage/01.png";
import detail_img_2 from "../../Assets/Images/DetailPage/02.png";
import detail_img_3 from "../../Assets/Images/DetailPage/03.png";
import "./style.scss";
function DetailPage() {
  return (
    <div className="detail-page">
      <div className="detail-page-banner">
        <div className="banner-img">
          <div className="banner-img-left">
            <img src={banner_img_1} alt="" />
            <img src={banner_img_2} alt="" />
          </div>
          <div className="banner-img-right">
            <img src={banner_img_3} alt="" />
          </div>
        </div>

        <div className="banner-info">
          <div className="info-name">Name</div>
          <div className="info-detail">
            <div className="title">
              <p>linda mccartney retrospective</p>
            </div>
            <div className="detail">
              <p>린다 매카트니 사진전 : 생애 가장 따뜻한 날들의 기록</p>
            </div>
          </div>
        </div>
      </div>

      <div className="information">
        <div className="information-name">Information</div>
        <div className="information-detail">
          <div className="title">
            <p>
              대림미술관은 2021 P4G 서울 정상회의를 기념하며,기후 변화 대응과
              지속 가능한 발전에 대한 고민으로 쓸모를 다해 버려졌던 물건들이
              예술로 변화하는 모습을 선보입니다. 분리수거를 하거나 에코백 및
              텀블러를 사용하는 모습 등 여러분의 일상에서 환경을 위한 작은
              실천이 담긴 SNS 포스팅을필수로 인증하셔야 전시 입장이 가능하오니
              방문에 참고 바랍니다. 환경을 위한 최소한의 노력이 일상의 작은
              시선으로부터 시작 될 수 있음을 널리 알리고자 진행되는 본 캠페인에
              적극적인 참여 부탁드립니다.
            </p>
          </div>
          <div className="subtitle">
            <p>
              In the following interview I conducted, Ms. Lee started by
              acknowledging the significant role of the Korea Archery
              Association in supporting the archers. She mentioned that the
              association always prepares archers thoroughly for the next
              Olympics as soon as the ongoing Olympics end. In this year’s
              Olympics, the association constructed a new archery field, a
              carbon copy of Yumenoshima park, where the games will take place.
              As a result, while the vivid blue-colored floors of Yumenoshima
              Park distracted some archers from accurately spotting their
              targets, the Korean archers performed at their best, thanks to the
              similar blue grounds of the national field.
            </p>
          </div>
        </div>
      </div>

      <div className="creator">
        <div className="creator-name">creator</div>
        <div className="creator-detail">
          {infoCreator(
            "youngmin kang",
            "강영민",
            "작가는 제작을 위해 재료공학, 로보틱스, 수학과 천문학 등 다양한 분야와 협업을 진행했고, 태양을 추적하고 한 지점으로 빛을 모으는 캘리브레이션 작업을 위해 머신러닝을 이용한 오픈소스 솔라팜 시스템을 개발했다. 2년간의 개발과 제작 기간을 거친 후 2018년 6월 런던의 서머셋 하우스(Somerset House)에서 처음 전시했다.",
            detail_img_1,
            "My Vintage Love, 2021 강영민",
            detail_img_2,
            "Earth in US 1, 2021 강영민"
          )}

          {infoCreator(
            "moonassi",
            "무나씨",
            "작가는 제작을 위해 재료공학, 로보틱스, 수학과 천문학 등 다양한 분야와 협업을 진행했고, 태양을 추적하고 한 지점으로 빛을 모으는 캘리브레이션 작업을 위해 머신러닝을 이용한 오픈소스 솔라팜 시스템을 개발했다. 2년간의 개발과 제작 기간을 거친 후 2018년 6월 런던의 서머셋 하우스(Somerset House)에서 처음 전시했다.",
            detail_img_3,
            "My Vintage Love, 2021 강영민"
          )}
        </div>
      </div>
    </div>
  );
}

export default DetailPage;

const infoCreator = (
  title: string,
  subtit: string,
  detail: string,
  img1: any,
  detailImg1: string,
  img2?: any,
  detailImg2?: string
) => (
  <div className="infoCreator">
    <div className="title">{title}</div>
    <div className="subtitle">{subtit}</div>
    <div className="detail">{detail}</div>
    <div className={`list-img ${!img2 ? "grid-img" : ""}`}>
      <div className="img-item">
        <img src={img1} alt="" />
        <p>{detailImg1}</p>
      </div>
      {img2 && (
        <div style={{paddingRight: 30}} className="img-item">
          <img src={img2} alt="" />
          <p>{detailImg2}</p>
        </div>
      )}
    </div>
  </div>
);
