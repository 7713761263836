import img1 from "../../Assets/Images/Gallery/installed-gallery-01.png";
import img2 from "../../Assets/Images/Gallery/installed-gallery-02.png";
import img3 from "../../Assets/Images/Gallery/installed-gallery-03.png";
import img4 from "../../Assets/Images/Gallery/installed-gallery-04.png";
import img5 from "../../Assets/Images/Gallery/installed-gallery-05.png";
import img6 from "../../Assets/Images/Gallery/installed-gallery-06.png";
import img7 from "../../Assets/Images/Gallery/installed-gallery-07.png";
import img8 from "../../Assets/Images/Gallery/installed-gallery-08.png";
import img9 from "../../Assets/Images/Gallery/installed-gallery-09.png";
import img10 from "../../Assets/Images/Gallery/installed-gallery-10.png";
import img11 from "../../Assets/Images/Gallery/installed-gallery-11.png";
import img12 from "../../Assets/Images/Gallery/installed-gallery-12.png";

export const GalleryData = [
    {
        status: "Completed",
        quantity: "1 ilwall",
        location: "포스코 스마트 데이터 센터 로비",
        time: "2021.12.08",
        img: img1,
        contentHover: "상문고 졸업앨범 키오스크\n\n상문고를 방문하는 분들이 상문고의 역사와\n 졸업사진을 보며 추억을 떠올릴 수 있게\n 구축하였습니다.",
        router: "/DDP",
    },

    {
        status: "Installing",
        quantity: "12 ilwall",
        location: "포스코 스마트 데이터 센터 로비",
        time: "2021.10.06",
        img: img2,
        contentHover: "posco smart data center lobby\n\n방문객들이 포스코 스마트 데이터 센터\n로비의 키오스크로 포스코의 다양한 면모를\n경험할 수 있습니다.",
        router: "/Infomation",
    },
    {
        status: "Installing",
        quantity: "6 ilwall",
        location: "DDP Interaction Media",
        time: "2021.10.06",
        img: img8,
        contentHover: "Sports Monster\n\n스포츠몬스터의 방문객들이 이용할 수 있는\n인터렉션 미디어를 구축하였습니다.",
        router: "/Media",
    },
    {
        status: "Installing",
        quantity: "3 ilwall",
        location: "Jurassic Museum Anmyon-do",
        time: "2017. 08. 24",
        img: img4,
        contentHover: "Anmyeondo Jurassic Museum\n\n안면도 쥬라기 박물관 방문객들이 이용할 수\n있는 인터랙션 미디어를 구축 하였습니다.",
        router: "/Jurassic",
    },
    {
        status: "Completed",
        quantity: "4 ilwall",
        location: "Gangnaum Galleria Media Facade",
        time: "2017. 08. 24",
        img: img5,
        contentHover: "Gangnam Galleria Media Facade\n\n강남 갤러리아 미디어 파사드 영상을 연출\n 하였습니다. ",
        router: "/Gangnaum",
    },
    {
        status: "Completed",
        quantity: "1 ilwall",
        location: "LG 투명 디스플레이 PRISM",
        time: "2017. 06. 22",
        img: img6,
        contentHover: "lg 투명 디스플레이 prism\n\n디지털 사이니지를 이용하여 lg\n투명디스플레이를 홍보하였습니다.",
        router: "/LG",
    },
    {
        status: "Completed",
        quantity: "1 ilwall",
        location: "SAMSUNG SDS Sangam Center",
        time: "2017. 08. 24",
        img: img7,
        contentHover: "Samsung SDS Sangam Center\n\nSamsung SDS 상암센터 안의\n인터렉티브 미디어를 구축하였습니다.",
        router: "SAMSUNG",
    },
    {
        status: "Installing",
        quantity: "6 ilwall",
        location: "우리부터 우리까지",
        time: "2017. 08. 24",
        img: img8,
        contentHover: "Sports Monster\n\n스포츠몬스터의 방문객들이 이용할 수 있는\n인터렉션 미디어를 구축하였습니다.",
        router: "/Infomation",
    },
    {
        status: "Installing",
        quantity: "7 ilwall",
        location: "Zhengzhou Library",
        time: "2017. 08. 24",
        img: img9,
        contentHover: "Zehngzhou Library\n\n정저우 도서관 인터랙티브 미디어를 \n구축하였습니다.",
        router: "/Zhengzhou",
    },
    {
        status: "Completed",
        quantity: "4 ilwall",
        location: "태안군 별똥별 하늘공원",
        time: "2017. 08. 24",
        img: img10,
        contentHover: "Taean Shooting Star Sky Park\n\n태안군 별똥별 하늘공원 인터렉션 미디어를\n구축하였습니다.",
        router: "/Zhengzhou",
    },
    {
        status: "Installing",
        quantity: "3 ilwall",
        location: "SAMSUNG SDS Sangam Center",
        time: "2017. 08. 24",
        img: img11,
        contentHover: "Samsung SDS 3Wall Cinema\n\nSamsung SDS 상암센터 내의  3Wall cinerama pr\nroom 미디어를 구축하였습니다. ",
        router: "/Sangam",
    },
    {
        status: "Completed",
        quantity: "1 ilwall",
        location: "DDP Infomation",
        time: "2017. 08. 24",
        img: img12,
        contentHover: "Dongdaemun Digital Plaza\n\n동대문 디지털 플라자 인포메이션 센터의\n인터랙션 미디어를 구축하였습니다.",
        router: "/Infomation",
    },
];
