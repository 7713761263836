import React from "react";
import img1 from "../../../Assets/Images/About/ilwall-value-02.png";
import img2 from "../../../Assets/Images/About/ilwall-value-01.png";
import img3 from "../../../Assets/Images/About/ilwall-value-03.png";
import "./style.scss";

function Value() {
  return (
    <div className="value">
      <div className="value-info">
        <div className="title">
          <h2>
            ILWall <br />
            Value
          </h2>
        </div>

        <div className="detail">
          <p>
            일월(ILWALL)은 박물관, 미술관, 학교, 기업 로비, 대형 카페 <br />
            등 다양한 공간을 특별하게 만들어드립니다. 하드웨어,
            <br />
            소프트웨어, 영상 콘텐츠를 A-Z까지 All In One 시스템으로
            <br />
            맞춤형 미디어 월을 제공합니다.
          </p>
        </div>
      </div>

      <div className="value-card">
        <div className="value-card-left">
          {infoCard(
            img1,
            "AIdentity Of Space",
            `인터랙티브 미디어 월 하나로 공간을 더 가치 있게,`,
            "더 화려하게, 더 특별하게 만들어보세요."
          )}
        </div>
        <div className="value-card-right">
          <div className="creative">
            {infoCard(
              img2,
              "Creative",
              `포화상태의 현대 광고산업에 소비자들의 참여도와 집중도를 높여줄 획기적인 크리에이티브 광고`
            )}
          </div>

          <div className="digital">
            {infoCard(
              img3,
              "Digital Library",
              `방문자에게 효과적인 전시와 교육, 정보 등을 제공하고,`,
              "능동적인 커뮤니케이션 플랫폼이 되어보세요."
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Value;

const infoCard = (
  img: any,
  title: string,
  detail: string,
  detail2?: string
) => (
  <>
    <div className="img">
      <img src={img} alt="" />
    </div>
    <div className="info">
      <div className="title">{title}</div>
      <div className="detail">
        {detail} <br />
        {detail2}
      </div>
    </div>
  </>
);
