import React, { useEffect, useRef, useState } from "react";
import "./style.scss";
import img from "../../Assets/Images/Gallery/contact.png";
import emailjs from "@emailjs/browser";
function Contact() {
    const [email, setEmail] = useState("");
    const [name, setName] = useState("");
    const [phone, setPhone] = useState("");
    const [request, setRequest] = useState("");
    const form = useRef();

    const handleInputChange = (e: any) => {
        console.log("email", email);
        console.log("name", name);
        console.log("phone", phone);
        console.log("request", request);
    };

    const sendEmail = () => {
        emailjs.init("User id");
        let templateParams = {
            name: name,
            phone: phone,
            email: email,
            request: request,
        };
        console.log(templateParams);
        emailjs.send("service_fwc32fb", "template_x81n32e", templateParams, "d4127rJrpv5jNO511").then(
            function (response) {
                console.log("Success!", response.status, response.text);
            },
            function (error) {
                console.log("Failed...", error);
            }
        );
    };
    return (
        <div className="contact-page">
            <div className="contact-form">
                {/* //info  */}
                <div className="contact-info">
                    <div className="info">
                        <div className="subtitle">Contacts</div>
                        <div className="title">
                            <p>
                                we would love to <br />
                                hear from you.
                            </p>
                        </div>
                        <div className="detail">
                            <p>
                                우리의 프로젝트에 대해 더 알고 싶은가요? <br />
                                아니면 우리의 팀에 합류하고 싶은가요? 지금 바로 메일을 보내주세요. <br />
                                우리는 여러분의 의견을 언제나 기다립니다.
                            </p>
                        </div>
                    </div>

                    <div className="address">
                        {infoAddress("Email", "hello@ilwall.com")}
                        {infoAddress("Phone", "010 9620 8645")}
                        {infoAddress("Visit Us", "57, Nonhyeon-ro151-gil,\nGangnam-gu, Seoul, Korea")}
                    </div>
                </div>

                {/* form  */}
                <form className="form">
                    {infoForm("Name", "이름을 알려주세요.", "Please write your name.", setName, name)}
                    {infoForm("E-mail", "답변 받으실 메일 주소를 알려주세요.", "Please write your e-mail address.", setEmail, email)}
                    {infoForm("Phone", "연락처를 알려주세요.", "Please write your phone number.", setPhone, phone)}
                    {infoForm("Request", "우리에게 보내고 싶은 내용을 입력해주세요.", "Please write a brief description.", setRequest, request, true)}

                    <div className="button-form">
                        <div onClick={sendEmail} className="form-submit">
                            <span>CONFIRM</span>
                        </div>
                    </div>
                </form>
            </div>

            <div className="contact-social">
                <div className="social-info">
                    <div className="subtitle">Follow Us</div>
                    <div className="title">
                        <a href="http://google.com" className="link-social">
                            Facebook
                        </a>
                        <a href="http://google.com" className="link-social">
                            instagram
                        </a>
                        <a href="http://google.com" className="link-social">
                            youtube
                        </a>
                        <a href="http://google.com" className="link-social">
                            behance
                        </a>
                    </div>
                </div>
                <div className="social-img">
                    <img src={img} alt="" />
                </div>
            </div>
        </div>
    );
}

export default Contact;

const infoAddress = (title: string, detail: string) => (
    <div className="infoAddress">
        <div className="infoAddress-title">{title}</div>
        <div className="infoAddress-detail">
            <a href="https://google.com">{detail}</a>
        </div>
    </div>
);

const infoForm = (name: string, detail: string, placeHolder: string, onChange: (e: string) => void, value: string, textArea: boolean = false) => (
    <div className="infoForm">
        <div className="name">{name}</div>
        <div className="detail">{detail}</div>

        <div style={{ height: textArea ? 200 : 60 }} className="form-input">
            {textArea ? (
                <textarea draggable="false" style={{ width: "100%" }} placeholder={placeHolder} value={value} onChange={(t) => onChange(t.target.value)}></textarea>
            ) : (
                <input style={{ width: "100%" }} placeholder={placeHolder} type="text" value={value} onChange={(t) => onChange(t.target.value)} />
            )}
        </div>
    </div>
);
