import main_img1 from "../../../Assets/Images/Home/mainbanner-big-01@2x.png";
import main_img2 from "../../../Assets/Images/Home/mainbanner-big-02@2x.png";
import main_img3 from "../../../Assets/Images/Home/mainbanner-big-03@2x.png";
import main_img4 from "../../../Assets/Images/Home/mainbanner-big-04@2x.png";
import side_banner_img1 from "../../../Assets/Images/Home/mainbanner-01.png";
import side_banner_img2 from "../../../Assets/Images/Home/mainbanner-02.png";
import side_banner_img3 from "../../../Assets/Images/Home/mainbanner-03.png";
import side_banner_img4 from "../../../Assets/Images/Home/mainbanner-04.png";

export const MainData: any[] = [
    {
        id: 0,
        title_en: "Ilwall\nOdyssey",
        title_ko: "Ilwall\nOdyssey",
        des_en: "The Infinity Wall",
        des_ko: "The Infinity Wall",
        side_banner_img: side_banner_img1,
        main_img: main_img1,
    },
    {
        id: 1,
        title_en: "Ilwall\nProject",
        title_ko: "Ilwall\nProject",
        des_en: "The Eternal Wall",
        des_ko: "The Eternal Wall",
        side_banner_img: side_banner_img2,
        main_img: main_img2,
    },
    {
        id: 2,
        title_en: "Ilwall\nOdyssey",
        title_ko: "Ilwall\nOdyssey",
        des_en: "The First Wall",
        des_ko: "The First Wall",
        side_banner_img: side_banner_img3,
        main_img: main_img3,
    },
    {
        id: 3,
        title_en: "Ilwall\nProject",
        title_ko: "Ilwall\nProject",
        des_en: "The wall of time",
        des_ko: "The wall of time",
        side_banner_img: side_banner_img4,
        main_img: main_img4,
    },
];
