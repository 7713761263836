import React from "react";
import "./style.scss";
import fb from "../../../Assets/Images/Home/sns-icon-01.png";
import youtube from "../../../Assets/Images/Home/sns-icon-02.png";
import ig from "../../../Assets/Images/Home/sns-icon-03.png";
import noname from "../../../Assets/Images/Home/sns-icon-04.png";

const dataIcons = [
    {
        icon: fb,
        router: "/",
    },
    {
        icon: youtube,
        router: "/",
    },
    {
        icon: ig,
        router: "/",
    },
    {
        icon: noname,
        router: "/",
    },
];
function Subscribe() {
    return (
        <main className="subscribe">
            <div className="subscribe-container">
                <div className="title">
                    <h2>
                        Subscribe to <br />
                        Circa Newsletter
                    </h2>
                    <div className="follow-title">Follow Us</div>
                </div>
                <div className="follow">
                    <ul className="list-icon">
                        {dataIcons.map((item, index) => (
                            <li key={index} className="list-icon-item">
                                <a href={item.router}>
                                    <img src={item.icon} alt="" />
                                </a>
                            </li>
                        ))}
                    </ul>
                    <div className="list-button">
                        <div className="email-input">
                            <input placeholder="Email Address" type="text" />
                        </div>
                        <div className="button-confirm">
                            <span>CONFIRM</span>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    );
}

export default Subscribe;
