import React, { useState } from "react";
import "./style.scss";
import arrow from "../../Assets/Images/News/arrow.png";
import arrowDown from "../../Assets/Images/News/arrow-down.png";
import img1 from "../../Assets/Images/News/newsletter-img-01.png";
import img2 from "../../Assets/Images/News/newsletter-img-02.png";
import img3 from "../../Assets/Images/News/newsletter-img-03.png";
import img4 from "../../Assets/Images/News/newsletter-img-04.png";
import img5 from "../../Assets/Images/News/newsletter-img-05.png";
import img6 from "../../Assets/Images/News/newsletter-img-06.png";
import img7 from "../../Assets/Images/News/newsletter-img-07.png";
import img8 from "../../Assets/Images/News/newsletter-img-08.png";
function News() {
    const [hiddenContent, setHiddenContent] = useState(false);
    return (
        <div className="news-page">
            <div className="news-title">
                <h2>Newsletter</h2>
            </div>
            <div className={`news-menu ${hiddenContent ? "news-menu-full" : ""}`}>
                <div className="menu-left">
                    {itemNews("포스코 Posco \n 스마트 데이터 센터 로비", "2022.12.16", img1)}
                    {itemNews("상문고등학교 : \n 디지털라이브러리", "2022.12.30", img4)}
                    {itemNews("SAMSUNG SDS \n Sangam Center", "202.09.28", img6)}
                </div>

                <div className="menu-center">
                    {itemNews("Gangnaum Galleria \n Media Facade", "2022.12.12", img2)}
                    {itemNews("DDP Digital Plaza \n Infomation Center Interaction Media", "2022.11.25", img5)}
                    {itemNews("Gangnaum Galleria \n Media Facade", "202.09.28", img7)}
                </div>

                <div className="menu-right">
                    {itemNews("SAMSUNG SDS \n Sangam Center", "2022.10.08", img3)}
                    {itemNews("포스코 Posco \n 스마트 데이터 센터 로비", "2022.11.25", img8)}
                </div>
            </div>
            {!hiddenContent && (
                <div className="news-more">
                    <div onClick={() => setHiddenContent(true)} className="button-more">
                        <span>MORE</span>
                        <img src={arrowDown} alt="" />
                    </div>
                </div>
            )}
        </div>
    );
}

export default News;

const itemNews = (title: string, date: string, img: any) => (
    <div className="itemNews">
        <img src={img} alt="" />
        <div className="contentImg">
            <div className="contentImg-left">
                <div className="title">{title}</div>
                <div className="date">{date}</div>
            </div>

            <div className="contentImg-right">
                <img src={arrow} alt="" />
            </div>
        </div>
    </div>
);
