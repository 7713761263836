import React from "react";
import chapter_1 from "../../../Assets/Images/About/about-bg-02.png";
import chapter_2 from "../../../Assets/Images/About/about-bg-03.png";
import chapter_3 from "../../../Assets/Images/About/about-bg-04.png";
import chapter_4 from "../../../Assets/Images/About/about-bg-05.png";

import "./style.scss";
const dataChapter = [
  {
    img: chapter_1,
    title: "Story",
    subtitle: "Chapter 1.",
    detail: `사람들은 위기나 해낼 수 없는 \n 무언가를 맞닥뜨렸을 때 벽을 떠올린다.`,
    darkColor: false,
  },
  {
    img: chapter_2,
    subtitle: "Chapter 2.",
    detail: `이 벽은 혼자 뚫고 헤쳐 나가기에는 \n 거대하고, 계속해서 존재한다.`,
    darkColor: false,
  },
  {
    img: chapter_3,
    subtitle: "Chapter 3.",
    detail: `일월은 새로운 시선으로 \n 넘을 수 있는 벽이자 통로가 되어 \n 다시금 새로운 세상을 만날 수 있게 하고자 한다.`,
    darkColor: true,
  },
  {
    img: chapter_4,
    subtitle: "Chapter 4.",
    detail: `이 벽을 넘어서 다시금 \n 새로운 세상을 알아 나갈 것이다.`,
    darkColor: true,
  },
];
function Chapters() {
  return (
    <div className="chapters">
      {dataChapter.map((item, index) => (
        <div key={index} className="chapters-section">
          <div className="title">
            <h2>{item.title}</h2>
          </div>
          <img src={item.img} alt="" />
          <div
            className={`chapters-section-info ${
              item.darkColor ? "darkColor" : ""
            }`}
          >
            <div className="title-section">{item.subtitle}</div>
            <div className="detail-section">{item.detail}</div>
          </div>
        </div>
      ))}
    </div>
  );
}

export default Chapters;
