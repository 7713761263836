import React, { useCallback, useRef, useState } from "react";
import moreIcon from "../../../Assets/Images/Home/more@2x.png";
import { Swiper, SwiperSlide } from "swiper/react";
import exhibition_1 from "../../../Assets/Images/exhibition-01.png";
import arrowLeft from "../../../Assets/Images/arrow-left.png";
import arrowRight from "../../../Assets/Images/arrow-right.png";
// Import Swiper styles
import "swiper/css";

import "./style.scss";

const dataSlide = [{ img: exhibition_1 }, { img: exhibition_1 }];
function Exhibition() {
    const sliderExhibitionRef = useRef(null) as any;
    const [currentIndex, setCurrentIndex] = useState(1);

    const handlePrev = useCallback(() => {
        if (!sliderExhibitionRef.current) return;
        sliderExhibitionRef.current?.swiper.slidePrev();
        setCurrentIndex(+sliderExhibitionRef.current?.swiper.realIndex + 1);
    }, []);

    const handleNext = useCallback(() => {
        if (!sliderExhibitionRef.current) return;
        sliderExhibitionRef.current.swiper.slideNext();
        setCurrentIndex(+sliderExhibitionRef.current?.swiper.realIndex + 1);
    }, []);
    return (
        <main className="exhibition">
            <div className="exhibition-container">
                <div className="content">
                    <div className="intro">
                        <div className="title">
                            <div className="subtitle">Construction Management</div>
                            <h2>
                                High School : <br />
                                Digital Library
                            </h2>
                            <div className="explain">
                                상문고등학교 :<br />
                                디지털라이브러리
                            </div>
                            <div className="detail">
                                <p>
                                    학교의 역사와 가치가 있는 정보를 공유하는 디지털라이브러리,
                                    <br />그 특별한 디지털 플랫폼을 ILWALL에서 맞춤형으로 제공합니다.
                                </p>
                            </div>
                            <div className="arrow-right">
                                <img src={moreIcon} alt="" />
                            </div>
                        </div>
                        <div className="navigation-custom">
                            <div className={`prev-arrow ${currentIndex === 1 ? "activeArrow" : ""}`} onClick={handlePrev}>
                                <img src={arrowLeft} alt="" />
                            </div>
                            <div className={`next-arrow ${currentIndex === dataSlide.length ? "activeArrow" : ""}`} onClick={handleNext}>
                                <img src={arrowRight} alt="" />
                            </div>
                        </div>
                    </div>
                    <div className="slide">
                        <Swiper
                            onRealIndexChange={(e) => {
                                setCurrentIndex(e.realIndex + 1);
                            }}
                            ref={sliderExhibitionRef}
                            className="mySwiper-exhibition"
                        >
                            {dataSlide.map((item, index) => (
                                <SwiperSlide key={index}>
                                    <img src={item.img} alt="" />
                                </SwiperSlide>
                            ))}
                        </Swiper>
                    </div>
                </div>
            </div>
        </main>
    );
}

export default Exhibition;
