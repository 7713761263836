import React from "react";
import "./App.scss";
import Footer from "./components/Footer/Footer";
import Header from "./components/Header/Header";
import Router from "./router/router";
import "./i18n";
function App() {
    return (
        <div className="App">
            <Router />
        </div>
    );
}

export default App;
