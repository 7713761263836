import React from "react";
import ArtService from "./ArtService/ArtService";
import Career from "./Career/Career";
import Commercial from "./Commercial/Commercial";
import Exhibition from "./Exhibition/Exhibition";
import HeroSection from "./HeroSection/HeroSection";
import "./style.scss";
import Subscribe from "./Subscribe/Subscribe";
function Home() {
    return (
        <div className="home-continer">
            <div className="home-warpper">
                <HeroSection />
                <Commercial />
                <ArtService />
                <Exhibition />
                <Subscribe />
                <Career />
            </div>
        </div>
    );
}

export default Home;
