import React from "react";
import Banner from "./Banner/Banner";
import Capabilities from "./Capabilities/Capabilities";
import Chapters from "./Chapters/Chapters";
import Value from "./Value/Value";
import './style.scss'

function About() {
  return (
    <div className="about-page">
      <Banner />
      <Chapters />
      <Value />
      <Capabilities />
    </div>
  );
}

export default About;
