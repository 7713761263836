import React from "react";
import img from "../../../Assets/Images/About/about-bg-06.png";
import plusIcon from "../../../Assets/Images/About/plus-icon.png";
import equalIcon from "../../../Assets/Images/About/equal-icon.png";
import "./style.scss";
function Capabilities() {
    return (
        <div className="capability">
            <img className="mainImg" src={img} alt="" />
            <div className="plus-icon">
                <img src={plusIcon} alt="" />
            </div>
            <div className="equal-icon">
                <img src={equalIcon} alt="" />
            </div>
            <div className="content">
                <div className="content-plus">
                    {detailContent("Solution", "인터랙티브 미디어 월 하나로 공간을 더 가치 있게,", "더 화려하게, 더 특별하게 만들어보세요.")}
                    {detailContent("Experience", "이용하는 사용자의 경험을 극대화 할 수 있는 ", "아트 및 디자인 콘텐츠 경험을 솔루션과 함께 제안 합니다.")}
                </div>
                <div className="content-equal">{detailContent("Capabilities", "일월은 디지털 미디어 기술과 사용자 편의성의 이해를 바탕으로 한 솔루션과", "디자인을 결합한 콘텐츠를 제공하여 경쟁력을 지닙니다.")}</div>
            </div>
        </div>
    );
}

export default Capabilities;

const detailContent = (title: string, detail1: string, detail2: string) => (
    <div className="content-detail">
        <div className="title">{title}</div>
        <div className="detail">
            {detail1} <br />
            {detail2}
        </div>
    </div>
);
