import { Route, Routes } from "react-router-dom";
import Home from "../pages/Home/index";
import About from "../pages/About/index";
import Gallery from "../pages/Gallery/Gallery";
import Contact from "../pages/Contact/Contact";
import Header from "../components/Header/Header";
import Footer from "../components/Footer/Footer";
import Space from "../pages/Space/Space";
import DetailPage from "../pages/DetailPage/DetailPage";
import News from "../pages/News/News";

const Router = () => {
    return (
        <>
            <Header />
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/about" element={<About />} />
                <Route path="/spacedesing" element={<Space />} />
                <Route path="/installedgallery" element={<Gallery />} />
                <Route path="/installedgallery/*" element={<DetailPage />} />
                <Route path="/contact" element={<Contact />} />
                <Route path="/newsletter" element={<News />} />
            </Routes>
            <Footer />
        </>
    );
};

export default Router;
