import React, { useCallback, useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";
import "swiper/css/grid";
import "./style.scss";
import "swiper/css/bundle";

import { FreeMode, Grid, Navigation, Thumbs } from "swiper";
import arrowLeft from "../../../Assets/Images/arrow-left.png";
import arrowRight from "../../../Assets/Images/arrow-right.png";
import { MainData } from "./MainData";
import i18n from "i18next";
import { useTranslation } from "react-i18next";
function HeroSection() {
    const { t } = useTranslation();
    const [thumbsSwiper, setThumbsSwiper] = useState(null);
    const sliderRef = useRef(null) as any;
    const [currentIndex, setCurrentIndex] = useState(1);
    const [data, setData] = useState(MainData);
    const [index, setIndex] = useState(0);

    const handlePrev = useCallback(() => {
        if (!sliderRef.current) return;
        sliderRef.current?.swiper.slidePrev();
        setCurrentIndex(+sliderRef.current?.swiper.realIndex + 1);
    }, []);

    const handleNext = useCallback(() => {
        if (!sliderRef.current) return;
        sliderRef.current.swiper.slideNext();
        setCurrentIndex(+sliderRef.current?.swiper.realIndex + 1);
    }, []);

    useEffect(() => {
        setIndex(sliderRef.current?.swiper.realIndex);
    }, [sliderRef.current?.swiper.realIndex]);

    return (
        <main className="herosection">
            <div className="herosection-container">
                <div className="herosection-grid">
                    <div className="top-title">
                        <div className="top-info">
                            <div className="left-info">
                                <span className="home-title">Introduction</span>
                                <div className="navigation-custom">
                                    <div className={`prev-arrow ${currentIndex === 1 ? "activeArrow" : ""}`} onClick={handlePrev}>
                                        <img src={arrowLeft} alt="" />
                                    </div>
                                    <div className={`next-arrow ${currentIndex === MainData.length ? "activeArrow" : ""}`} onClick={handleNext}>
                                        <img src={arrowRight} alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="mid-box">
                        <div className="mid">
                            <div className="mid-info">
                                <span className="mid-title">{i18n.language === "en" ? data[index].title_en : data[index].title_ko}</span>
                                <div className="mid-more">
                                    <h5>
                                        <Link to="/about">ABOUT US</Link>
                                    </h5>
                                </div>
                            </div>
                            <div className="mid-info-2">
                                <span className="mid-title">{i18n.language === "en" ? data[index].des_en : data[index].des_ko}</span>
                            </div>
                        </div>
                        <span className="end-info">
                            {currentIndex} / {MainData.length}
                        </span>
                    </div>
                    <div className="bottom-left">
                        <div className="slide-detail">
                            <div className="slide">
                                <Swiper
                                    onSwiper={(e) => {
                                        setThumbsSwiper(e as any);
                                    }}
                                    spaceBetween={15}
                                    slidesPerView={3}
                                    slidesPerGroup={1}
                                    direction="vertical"
                                    modules={[Grid, FreeMode, Navigation, Thumbs]}
                                    watchSlidesProgress={true}
                                    freeMode={true}
                                    className="mySwiper"
                                >
                                    {MainData.map((item, index) => (
                                        <SwiperSlide key={index}>
                                            <img src={item.side_banner_img} alt="" />
                                        </SwiperSlide>
                                    ))}
                                </Swiper>
                            </div>
                        </div>
                    </div>
                    <div className="bottom-right">
                        <div className="slide-detail">
                            <div className="slide">
                                <Swiper
                                    spaceBetween={10}
                                    thumbs={{ swiper: thumbsSwiper }}
                                    modules={[FreeMode, Navigation, Thumbs]}
                                    className="mySwiper"
                                    ref={sliderRef}
                                    onRealIndexChange={(e) => {
                                        setCurrentIndex(e.realIndex + 1);
                                    }}
                                >
                                    {MainData.map((item, index) => (
                                        <SwiperSlide key={index}>
                                            <img src={item.main_img} alt="" />
                                        </SwiperSlide>
                                    ))}
                                </Swiper>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    );
}

export default HeroSection;
